import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import { Container, Row, Col } from "react-bootstrap";

const Cta = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- CTA Area --> */}
      <div className="cta-section pt-13 pt-lg-25 pb-13 pb-lg-25 bg-blue bg-pattern pattern-7">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <div className="text-center dark-mode-texts">
                <h2 className="gr-text-4 mb-7 text-white">{t('cta_title')}</h2>
                <p className="gr-text-9 mb-11 px-xl-7 gr-text-color">
                  {t('cta_body')}
                </p>
                <Link
                  to="/contact"
                  className="btn btn-green text-white gr-hover-y px-lg-9"
                >
                  {t('cta_cta')}
                </Link>
                <p className="gr-text-11 mb-0 mt-6 text-color-opacity">{t('cta_details')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Cta;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

